import React from "react";
import { Link } from 'react-router-dom';
import Header from "../components/Header";
import Footer from "../components/Footer";
// import Carousel from 'react-bootstrap/Carousel';
// import ExampleCarouselImage from "../images/slide-01.jpg";

const Home = () => {
    return (
        <>
            <Header activePath="/" />

            {/* ***** Main Banner Area Start ***** */}
            {/* <Carousel>
      <Carousel.Item>
        <ExampleCarouselImage text="First slide" />
        <Carousel.Caption>
          <h3>First slide label</h3>
          <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <ExampleCarouselImage text="Second slide" />
        <Carousel.Caption>
          <h3>Second slide label</h3>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <ExampleCarouselImage text="Third slide" />
        <Carousel.Caption>
          <h3>Third slide label</h3>
          <p>
            Praesent commodo cursus magna, vel scelerisque nisl consectetur.
          </p>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel> */}
            <div className="swiper-container" id="top">
                <div className="swiper-wrapper">
                    <div className="swiper-slide">
                        <div
                            className="slide-inner"
                            style={{ backgroundImage: `url(assets/images/slide-01.jpg)` }}
                        >
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-8">
                                        <div className="header-text">
                                            <h2>
                                                Reader are <em>leader</em>, leader are reader
                                            </h2>
                                            <div className="div-dec"></div>
                                            <p></p>
                                            <div className="buttons">
                                                <div className="green-button">
                                                    <Link to="/about">Découvrir Plus</Link>
                                                </div>
                                                <div className="orange-button">
                                                    <Link to="/contact">Contactez-Nous</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="swiper-slide">
                        <div
                            className="slide-inner"
                            style={{ backgroundImage: `url(assets/images/slide-02.jpg)` }}
                        >
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-8">
                                        <div className="header-text">
                                            <h2>
                                                Conservation du <em>patrimoine</em>
                                            </h2>
                                            <div className="div-dec"></div>
                                            <p>
                                                Nous visons à préserver et à conserver des œuvres
                                                littéraires, historiques et culturelles pour les
                                                générations futures.
                                            </p>
                                            <div className="buttons">
                                                <div className="green-button">
                                                    <Link to="/about">Découvrir Plus</Link>
                                                </div>
                                                <div className="orange-button">
                                                    <Link to="/contact">Contactez-Nous</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="swiper-slide">
                        <div
                            className="slide-inner"
                            style={{ backgroundImage: `url(assets/images/slide-03.jpg)` }}
                        >
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-8">
                                        <div className="header-text">
                                            <h2>
                                                Accès à la <em>connaissance</em> et à l'éducation
                                            </h2>
                                            <div className="div-dec"></div>
                                            <p>
                                                Nous permettons un accès libre et équitable à
                                                l'information, à l'éducation et à la culture pour tous.
                                            </p>
                                            <div className="buttons">
                                                <div className="green-button">
                                                    <Link to="/about">Découvrir Plus</Link>
                                                </div>
                                                <div className="orange-button">
                                                    <Link to="/contact">Contactez-Nous</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="swiper-button-next swiper-button-white"></div>
                <div className="swiper-button-prev swiper-button-white"></div> */}
            </div>

            {/* ***** Main Banner Area End ***** */}

            <section className="about-us" id="about">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 offset-lg-3">
                            <div className="section-heading">
                                <h6>À Propos</h6>
                                <h4>Découvrez-Nous Mieux</h4>
                            </div>
                        </div>
                        <div className="col-lg-8">
                            <div className="naccs">
                                <div className="tabs">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="menu">
                                                <div className="active gradient-border">
                                                    <span>Presentation</span>
                                                </div>
                                                <div className="gradient-border">
                                                    <span>Historique</span>
                                                </div>
                                                <div className="gradient-border">
                                                    <span>Mission</span>
                                                </div>
                                                <div className="gradient-border">
                                                    <span>Buts</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <ul className="nacc">
                                                <li className="active">
                                                    <div style={{ textAlign: "justify" }}>
                                                        Comment parler de la World Conquest Library sans
                                                        évoquer sa taille et son fond documentaire aussi
                                                        impressionnant que varié. Conçue pour être une
                                                        institution de référence dans le secteur de la
                                                        bibliothéconomie, la WCL cultive une identité
                                                        singulière et hybride partagée entre la bibliothèque
                                                        traditionnelle et la recherche scientifique. La WCL
                                                        se veut être un pôle de production et de diffusion
                                                        des intelligences pour l’impact des nations. Pour
                                                        accomplir notre mission selon la Vision de la CMCI
                                                        dont nous sommes l’émanation, notre morphologie
                                                        n’est comparable à aucune bibliothèque
                                                        contemporaine. Non seulement la WCL réunit les
                                                        ouvrages les plus importants de son époque, mais la
                                                        World Conquest Library est un catalyseur et un
                                                        diffuseur des savoirs par le biais des sociétés
                                                        d’émulations qu’elle héberge. Conçue pour produire
                                                        les savoirs, loger les savants et impulser
                                                        l’innovation, la Bibliothèque ZTF, est le temple de
                                                        la connaissance holistique contemporaine.
                                                        <br />
                                                        <br />
                                                        La World Conquest Library en Chiffres c’est :
                                                        <br />
                                                        <br />
                                                        🏢 Un site bâti sur 2500m²; <br />
                                                        📚 Plus de 170.000 livres; <br />
                                                        📖 Une salle de collection qui expose en permanence
                                                        50.000 livres; <br />
                                                        💺 Une salle de lecture polyvalente comprenant des
                                                        loges dédiées; <br />
                                                        👩‍🏫 Des salles de conférences polyvalentes; <br />
                                                        🍽️ Un restaurant gastronomique accolé à un bar à
                                                        glace moderne; <br />
                                                        🏠 Des cases de passages pour résidence de lecture,
                                                        d’écriture ou de recherche; <br />
                                                        🚀 Un mini parc d’attraction.
                                                    </div>
                                                </li>
                                                <li>
                                                    <div style={{ textAlign: "justify" }}>
                                                        Le professeur Zacharias Tanee Fomum était un
                                                        amoureux et un passionné de lecture. Au cours de sa
                                                        vie, il a lu plus de 1300 livres ; principalement de
                                                        la littérature chrétienne et scientifique. Pour
                                                        illustrer la place de la lecture dans sa vie, nous
                                                        aimons présenter cette phrase : « Les livres sont
                                                        inoubliables. Il y a de la puissance dans ce qu’un
                                                        Homme lit. J’ai été fait par ce que les gens ont
                                                        écrit. » <br />
                                                        <br />
                                                        Eminent homme de science, le Professeur Zacharias
                                                        Tanee Fomum a obtenu un PhD en Chimie organique à
                                                        l’Université de Makerere à Kampala (Uganda) et un
                                                        DsC décerné par l’Université de Durham en Grande
                                                        Bretagne. Il fut d’ailleurs, le deuxième Africain à
                                                        accéder à cette haute distinction académique. Le
                                                        Professeur Zacharias Tanee Fomum a supervisé et Co
                                                        supervisé plus d’une centaine de mémoires et thèses
                                                        de maitrises et Doctorats. Il est auteur de plus de
                                                        160 articles scientifiques parus dans les journaux
                                                        de haute référence. <br />
                                                        <br />
                                                        Serviteur dévoué de Dieu, le Frère Zach (comme
                                                        l’appelle affectueusement la Communauté chrétienne
                                                        universelle), a su poser la fondation de la
                                                        Communauté Missionnaire Chrétienne Internationale.
                                                        Il a réussi à bâtir une œuvre solide qui est
                                                        aujourd’hui présente dans 143 nations dans le monde
                                                        et compte plus de 6 millions de membres. <br />
                                                        <br />
                                                        Le Professeur Zacharias Tanee Fomum est un auteur
                                                        chrétien prolifique qui compte plus de 500 livres
                                                        dans son escarcelle. Ses œuvres traduites en plus de
                                                        quatre langues sont disponibles et continuent de
                                                        parcourir le monde plus d’une décennie après son
                                                        décès.
                                                        <br />
                                                        <br />
                                                        La World Conquest Library est née d’une injonction
                                                        directe. La naissance de cette bibliothèque est
                                                        effectivement un ordre direct que le Seigneur a
                                                        instruit le Frère Zach à travers une vision de
                                                        mettre sur pied une bibliothèque pour la conquête du
                                                        monde dotée d’un million de livres chrétiens. Dès
                                                        lors, le Frère Zach n’a jamais eu de cesse
                                                        d’encourager toutes les personnes placées sous son
                                                        leadership à s’adonner à la lecture. <br />
                                                        <br />
                                                        L’on peut dire que sa grande passion pour la lecture
                                                        et sa quête permanente de l’excellence, constituent
                                                        l’héritage génétique de la nation ZTF qu’il a
                                                        spirituellement engendré. Voilà de façon maladroite
                                                        l’historique de la World Conquest Library ainsi
                                                        esquissée. <br />
                                                        <br />
                                                        Sur le plan chronologique et anecdotique, le Frère
                                                        Oukoro fut le premier à se saisir de la vision de la
                                                        bibliothèque pour la conquête du monde. Il rassembla
                                                        une première vague d’environ 8000 livres qu’il
                                                        envoya au Quartier Général de la CMCI. Il compléta
                                                        cette première vague à 15.000 livres. Par la suite,
                                                        le Ministère a reçu un don de livres de l’Australie.
                                                        <br />
                                                        <br />
                                                        C’est avec ce fond que la Bibliothèque pour la
                                                        Conquête du Monde a lancé ses activités en 2020.
                                                        Bien avant le lancement en 2020, Frère Zach décédait
                                                        en 2009 et le Frère Théodore Andosseh le succédait
                                                        comme Leader Mondial de notre œuvre. <br />
                                                        <br />
                                                        Le Frère Theodore décida d’implémenter la vision de
                                                        la bibliothèque en désignant le Frère John Bapack
                                                        pour l’assister dans la gestion de ce département.
                                                        Au lancement des activités de la WCL en avril 2020,
                                                        des dons en livres nous sont parvenus du Cameroun,
                                                        de l’Australie et du Canada. <br />
                                                        <br />
                                                        La World Conquest Library a ainsi décollé avec un
                                                        fond de 40.000 livres.
                                                    </div>
                                                </li>

                                                <li>
                                                    <div style={{ textAlign: "justify" }}>
                                                        En 1997, dans la ville de Colorado Spring aux Etats
                                                        Unies, Cindy Jacobs qui n’avait pas encore rencontré
                                                        Le Prof. Zacharis Tanee Fomum reçoit de la part du
                                                        Seigneur Jésus Christ, la prophétie dite de Colorado
                                                        Spring. Cindy Jacobs est alors amenée à chercher le
                                                        Prof ZTF afin de lui délivrer le message prophétique
                                                        qu’elle avait reçu pour lui. Dans ce message se
                                                        trouvait l’instruction de mettre sur pied une
                                                        bibliothèque pour la conquête du monde doté d’un
                                                        million de livres chrétiens. La WCL est une
                                                        bibliothèque singulière qui s’est dotée d’une
                                                        mission singulière. Notre mission primaire est donc
                                                        de poursuivre le but de 1 million de livres
                                                        chrétiens. La WCL poursuit également le but
                                                        d’apporter une contribution particulière au monde en
                                                        obéissance à Matthieu 5 :14-15…
                                                    </div>
                                                </li>
                                                <li>
                                                    <div style={{ textAlign: "justify" }}>
                                                        Semer la graine de l’amour de la lecture de la
                                                        littérature à chaque personne de cette génération
                                                        afin de produire des Hommes ayant suffisamment de
                                                        contenu et capables d’impacter leur milieu de vie.
                                                        <br />
                                                        <br />
                                                        Former 1.000.000 de jeunes gens selon les valeurs
                                                        que nous défendons et capable d’intégrer les quatre
                                                        dimensions du développement humain dans le processus
                                                        d’édification de l’Homme. Nous pensons impulser une
                                                        nouvelle dynamique sur le socle de ces 4 dimensions
                                                        :
                                                        <br />
                                                        <br />💪🏾 Physique;<br />🧠 Mentale; <br />😊 Emotionnelle; <br />✝️ Spirituelle.
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="right-content">
                                <h4>Reader is leader, leader is reader</h4>
                                <p>
                                    Les livres sont inoubliables. Il y a de la puissance dans ce
                                    qu’un Homme lit. J’ai été fait par ce que les gens ont écrit.
                                    <br />
                                    <br />
                                    Books are unforgettable. There is power in what a Man reads. I
                                    was made by what people wrote <br />
                                    <br />
                                    <b>Prof. Zachaias Tanee Fomum</b>
                                </p>
                                <div className="green-button">
                                    <Link to="/about">Découvrir Plus</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="services">
                <div className="container px-4 py-5" id="custom-cards">
                    <div className="col-lg-6 offset-lg-3">
                        <div className="section-heading">
                            <h6>Ce que nous vous proposont de lire</h6>
                            <h4>Livre de la semaine</h4>
                        </div>
                    </div>

                    <div className="jhj">
                        <div className="col d-flex flex-column gap-1">
                            <div
                                className="h-100 overflow-hidden text-white bg-dark rounded-5 shadow-lg jj"
                                style={{ backgroundImage: `url('./assets/images/livres/li1.jpg')` }}
                            ></div>
                        </div>

                        <div className="col">
                            <div
                                className="h-100 overflow-hidden text-white bg-dark rounded-5 shadow-lg jj"
                                style={{ backgroundImage: `url('./assets/images/livres/li2.jpg')` }}
                            ></div>
                        </div>

                        <div className="col">
                            <div
                                className="h-100 overflow-hidden text-white bg-dark rounded-5 shadow-lg jj"
                                style={{ backgroundImage: `url('./assets/images/livres/li3.jpg')` }}
                            ></div>
                        </div>

                        <div className="col">
                            <div
                                className="h-100 overflow-hidden text-white bg-dark rounded-5 shadow-lg jj"
                                style={{ backgroundImage: `url('./assets/images/livres/li4.jpeg')` }}
                            ></div>
                        </div>

                        <div className="col">
                            <div
                                className="h-100 overflow-hidden text-white bg-dark rounded-5 shadow-lg jj"
                                style={{ backgroundImage: `url('./assets/images/livres/li5.jpg')` }}
                            ></div>
                        </div>

                        <div className="col">
                            <div
                                className="h-100 overflow-hidden text-white bg-dark rounded-5 shadow-lg jj"
                                style={{ backgroundImage: `url('./assets/images/livres/li6.jpg')` }}
                            ></div>
                        </div>

                        <div className="col">
                            <div
                                className="h-100 overflow-hidden text-white bg-dark rounded-5 shadow-lg jj"
                                style={{ backgroundImage: `url('./assets/images/livres/li7.jpg')` }}
                            ></div>
                        </div>

                        <div className="col">
                            <div
                                className="h-100 overflow-hidden text-white bg-dark rounded-5 shadow-lg jj"
                                style={{ backgroundImage: `url('./assets/images/livres/li8.jpg')` }}
                            ></div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="carou">
                <div className="col-lg-6 offset-lg-3">
                    <div className="section-heading">
                        <h6>Ce que nous vous proposont à lire</h6>
                        <h4>Livre Fart</h4>
                    </div>
                </div>
                <div className="container">
                    <input type="radio" name="slider" id="item-1" checked />
                    <input type="radio" name="slider" id="item-2" />
                    <input type="radio" name="slider" id="item-3" />
                    <div className="cards">
                        <label className="card" htmlFor="item-1" id="song-1">
                            <img src="./assets/images/livres/li1.jpg" alt="song" />
                        </label>
                        <label className="card" htmlFor="item-2" id="song-2">
                            <img src="./assets/images/livres/li2.jpg" alt="song" />
                        </label>
                        <label className="card" htmlFor="item-3" id="song-3">
                            <img src="./assets/images/livres/li3.jpg" alt="song" />
                        </label>
                    </div>
                    <div className="player">
                        <div className="upper-part">
                            <div className="info-area" id="test">
                                <label className="song-info" id="song-info-1">
                                    <div className="title">The way of life</div>
                                    <div className="sub-line">
                                        <div className="subtitle">Zacharias Tanee Fomum</div>
                                    </div>
                                </label>
                                <label className="song-info" id="song-info-2">
                                    <div className="title">L'utilisation du temps</div>
                                    <div className="sub-line">
                                        <div className="subtitle">Zacharias Tanee Fomum</div>
                                    </div>
                                </label>
                                <label className="song-info" id="song-info-3">
                                    <div className="title">Le ministère du jeune</div>
                                    <div className="sub-line">
                                        <div className="subtitle">Zacharias Tanee Fomum</div>
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="testimonials" id="testimonials">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 offset-lg-3">
                            <div className="section-heading">
                                <h6>Témoignages</h6>
                                <h4>Ce Qu'ils Disent</h4>
                            </div>
                        </div>
                        <div className="col-lg-10 offset-lg-1">
                            <div className="owl-testimonials owl-carousel">
                                <div className="item">
                                    <i className="fa fa-quote-left"></i>
                                    <p>
                                        “Donec et nunc massa. Nullam non felis dignissim, dapibus
                                        turpis semper, vulputate lorem. Nam volutpat posuere tellus,
                                        in porttitor justo interdum nec. Aenean in dapibus risus, in
                                        euismod ligula. Aliquam vel scelerisque elit.”
                                    </p>
                                    <h4>David Eigenberg</h4>
                                    <span>CEO of Mexant</span>
                                    <div className="right-image">
                                        <img src="assets/images/testimonials-01.jpg" alt="testimonial" />
                                    </div>
                                </div>
                                <div className="item">
                                    <i className="fa fa-quote-left"></i>
                                    <p>
                                        “Etiam id ligula risus. Fusce fringilla nisl nunc, nec
                                        rutrum lectus cursus nec. In blandit nibh dolor, at rutrum
                                        leo accumsan porta. Nullam pulvinar eros porttitor risus
                                        condimentum tempus.”
                                    </p>
                                    <h4>Andrew Garfield</h4>
                                    <span>CTO of Mexant</span>
                                    <div className="right-image">
                                        <img src="assets/images/testimonials-01.jpg" alt="testimonial" />
                                    </div>
                                </div>
                                <div className="item">
                                    <i className="fa fa-quote-left"></i>
                                    <p>
                                        “Ut dictum vehicula massa, ac pharetra leo tincidunt eu.
                                        Phasellus in tristique magna, ac gravida leo. Integer sed
                                        lorem sapien. Ut viverra mauris sed lobortis commodo.”
                                    </p>
                                    <h4>George Lopez</h4>
                                    <span>Crypto Manager</span>
                                    <div className="right-image">
                                        <img src="assets/images/testimonials-01.jpg" alt="testimonial" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer/>
        </>
    );
};

export default Home;
