import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';

const Service = () => {
    return (
        <>
            <Header activePath="/service"/>
            <div class="page-heading">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="header-text">
                                <h2>Nos Services</h2>
                                <div class="div-dec"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* ***** Main Banner Area End ***** */}

            <section class="main-services">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="service-item">
                                <div class="row">
                                    <div class="col-lg-6">
                                        <div class="left-image">
                                            <img src="assets/images/service-image-01.jpg" alt="" />
                                        </div>
                                    </div>
                                    <div class="col-lg-6 align-self-center">
                                        <div class="right-text-content">
                                            <i class="fas fa-archive"></i>
                                            <h4>Centre de Référence et de Recherche ZTF</h4>
                                            <p>Un espace où des experts de haut niveau collaborent sur des questions stratégiques, avec pour objectif d'influencer les orientations scientifiques à l'échelle nationale et internationale.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="service-item">
                                <div class="row">
                                    <div class="col-lg-6 align-self-center">
                                        <div class="left-text-content">
                                            <i class="fas fa-archive"></i>
                                            <h4>Sociétés Savantes</h4>
                                            <p>Groupements d'érudits qui se réunissent pour des sessions d'éveil scientifique, souvent commanditées pour répondre à des besoins stratégiques spécifiques. Ces activités débouchent généralement sur la publication d'articles et d'ouvrages collectifs.</p>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="right-image">
                                            <img src="assets/images/service-image-02.jpg" alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="service-item">
                                <div class="row">
                                    <div class="col-lg-6">
                                        <div class="left-image">
                                            <img src="assets/images/service-image-03.jpg" alt="" />
                                        </div>
                                    </div>
                                    <div class="col-lg-6 align-self-center">
                                        <div class="right-text-content">
                                            <i class="fas fa-archive"></i>
                                            <h4>Revues Scientifiques</h4>
                                            <p>Publications dédiées à la diffusion des travaux de recherche, offrant une plateforme aux innovateurs pour partager leur vision et imposer des modèles scientifiques dans les sphères d'influence.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="service-item last-service">
                                <div class="row">
                                    <div class="col-lg-6 align-self-center">
                                        <div class="left-text-content">
                                            <i class="fas fa-archive"></i>
                                            <h4>Centre Linguistique Appliqué</h4>
                                            <p>Formations en langues étrangères, avec certifications reconnues, permettant à nos usagers de développer une maîtrise courante de langues telles que le français, l'anglais, l'allemand, l'espagnol, le chinois, et bien d'autres .</p>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="right-image">
                                            <img src="assets/images/service-image-02.jpg" alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="simple-cta">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-5">
                            <h4>Business <em>Solutions</em> and <strong>Crypto</strong> Investments</h4>
                        </div>
                        <div class="col-lg-7">
                            <div class="buttons">
                                <div class="green-button">
                                    <a href="#">Discover More</a>
                                </div>
                                <div class="orange-button">
                                    <a href="#">Contact Us</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <section class="service-details">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6 offset-lg-3">
                            <div class="section-heading">
                                <h6>Investment in Details</h6>
                                <h4>Upgrade your knowledge</h4>
                            </div>
                        </div>
                        <div class="col-lg-10 offset-lg-1">
                            <div class="naccs">
                                <div class="tabs">
                                    <div class="row">
                                        <div class="col-lg-12">
                                            <div class="menu">
                                                <div class="active gradient-border"><span>Crypto Investments</span></div>
                                                <div class="gradient-border"><span>Cryptocurrency Market</span></div>
                                                <div class="gradient-border"><span>Financial Planning</span></div>
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <ul class="nacc">
                                                <li class="active">
                                                    <div>
                                                        <div class="left-image">
                                                            <img src="assets/images/service-details-01.jpg" alt="" />
                                                        </div>
                                                        <div class="right-content">
                                                            <h4>Learn more about cryptocurrency investments</h4>
                                                            <p>Etiam id ligula risus. Fusce fringilla nisl nunc, nec rutrum lectus cursus nec. In blandit nibh dolor, at rutrum leo accumsan porta. Nullam pulvinar eros porttitor risus condimentum tempus.</p>
                                                            <span>- Top Crypto prices and charts</span>
                                                            <span>- Is Cryptocurrency a good investment?</span>
                                                            <span class="last-span">- Crypto Market Insiders and News</span>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div>
                                                        <div class="left-image">
                                                            <img src="assets/images/service-details-02.jpg" alt="" />
                                                        </div>
                                                        <div class="right-content">
                                                            <h4>Read more on Cryptocurrency Market</h4>
                                                            <p>Fusce fringilla nisl nunc, nec rutrum lectus cursus nec. In blandit nibh dolor, at rutrum leo accumsan porta. Nullam pulvinar eros porttitor risus condimentum tempus.</p>
                                                            <span>- Digital Currency Exchange</span>
                                                            <span>- Trading Cryptocurrencies Online</span>
                                                            <span class="last-span">- Different Categories of Digital Currencies</span>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div>
                                                        <div class="left-image">
                                                            <img src="assets/images/service-details-03.jpg" alt="" />
                                                        </div>
                                                        <div class="right-content">
                                                            <h4>How to carefully plan on your online financials</h4>
                                                            <p>Pellentesque ipsum elit, congue a sapien laoreet, pellentesque ultricies risus. Nulla facilisi. Mauris vitae lacinia magna. Nam euismod sapien sit amet elementum blandit. Nulla non placerat neque.</p>
                                                            <span>- Financial Planning and Investments</span>
                                                            <span>- Business Networking</span>
                                                            <span class="last-span">- Managing Digital Assets</span>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            <Footer/>
        </>
    );
}

export default Service;
