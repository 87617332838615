import React from 'react';
import cph from "../images/cph.png";
import peztf from "../images/IMG-20231227-WA0023-removebg-preview (1).png";
import cmci from "../images/cmci-benin-noir.png";
import ecsd from "../images/images.jpeg";
import umpj from "../images/hqdefault.jpg";
import hc from "../images/download.png";

const Footer = () => {
    return (
        <>
            <section class="partners">
                <div class="container">
                    <div class="row">
                    <div class="col-lg-2 col-sm-4 col-6">
          <div class="item">
            <a href="https://cph-koume.com/">
              <img src={cph} alt="" />
            </a>
          </div>
        </div>
        <div class="col-lg-2 col-sm-4 col-6">
          <div class="item">
            <a href="https://cm.excellenceawardsztf.com/">
              <img src={peztf} alt="" />
            </a>
          </div>
        </div>
        <div class="col-lg-2 col-sm-4 col-6">
          <div class="item">
            <a href="https://cmfionline.org/fr/">
              <img src={cmci} alt="" />
            </a>
          </div>
        </div>
        <div class="col-lg-2 col-sm-4 col-6">
          <div class="item">
            <a href="https://cmfionline.org/fr/depts/school-of-knowing-serving-god-sksg/">
              <img src={ecsd} alt="" />
            </a>
          </div>
        </div>
        <div class="col-lg-2 col-sm-4 col-6">
          <div class="item">
            <a href="https://cmfionline.org/fr/depts/world-university-of-prayer-and-fasting-wupf/">
              <img src={umpj} alt="" />
            </a>
          </div>
        </div>
        <div class="col-lg-2 col-sm-4 col-6">
          <div class="item">
            <a href="https://cmfionline.org/fr/depts/https://hopeclinic-koume.org/">
              <img src={hc} alt="" />
            </a>
          </div>
        </div>
                    </div>
                </div>
            </section>
            <footer>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <p>Copyright © 2024 World Conquest Library. Tous droits réservés.

                                <br />Conçu par <a title="CSS Templates" rel="sponsored" href="https://cmfiwcl.org/" target="_blank">World Conquest Library</a></p>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
}

export default Footer;
