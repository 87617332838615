import React from 'react';
import Header from '../components/Header';
import { Link } from 'react-router-dom';
import Footer from '../components/Footer';

const About = () => {
    return (
        <div>

            <Header activePath="/about" />

            <div className="page-heading">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="header-text">
                                <h2>À Propos</h2>
                                <div className="div-dec"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/*  ***** Main Banner Area End ***** */}

            <section className="top-section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="left-image">
                                <img src="assets/images/about-left-image.jpg" alt="" />
                            </div>
                        </div>
                        <div className="col-lg-6 align-self-center">
                            <div className="content-section">
                                <article className="content-item">
                                    <div className="content-header">
                                        <h3>La World Conquest Library (WCL)</h3>
                                    </div>
                                    <div className="content-body">
                                        <p>Une institution unique en son genre, dédiée à la diffusion du savoir et à l'impact global à travers la connaissance.
                                            Issue de la vision prophétique reçue par le Professeur Zacharias Tanee Fomum, la WCL a été fondée pour devenir un pôle de production et de diffusion des intelligences,
                                            visant à influencer positivement les nations. Depuis son lancement en 2020, la WCL a réussi à constituer une impressionnante collection de plus de 170 000 ouvrages
                                            et à créer un espace dédié à la recherche et à l'innovation.</p>
                                    </div>
                                </article>
                                <article className="content-item">
                                    <div className="content-header">
                                        <h3>Mission de la WCL</h3>
                                    </div>
                                    <div className="content-body">
                                        <p>La mission de la WCL est de semer l'amour de la lecture et de la littérature au sein des nouvelles générations, en formant des individus capables d'impacter leur environnement à travers les dimensions physique, mentale, émotionnelle et spirituelle. Nous aspirons à établir des bibliothèques dans 250 nations, contribuant ainsi à l'édification d'une humanité éclairée et bien informée.</p>
                                    </div>
                                </article>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <section className="simple-cta">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5">
                            <h4>N'hésitez pas à faire un tour</h4>
                        </div>
                        <div className="col-lg-7">
                            <div className="buttons">
                                {/* <div className="green-button">
                                    <a href="#">Discover More</a>
                                </div>  */}
                                <div className="orange-button">
                                    <Link to="/contact">Contact Us</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="what-we-do">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5">
                            <div className="left-content">
                                <h4>La World Conquest Library (WCL) </h4>
                                <p>est une institution internationale consacrée à l'expansion du savoir et à l'impact global par la diffusion de la connaissance. Fondée sur les valeurs de leadership, d'excellence académique et de transformation sociale, la WCL s'engage à cultiver une génération de leaders éclairés capables de transformer leur environnement. En nous appuyant sur une vision globale et des partenariats stratégiques, nous travaillons sans relâche pour établir un réseau mondial de bibliothèques, devenant ainsi un acteur clé dans la diffusion du savoir à travers le monde.</p>
                                <div className="green-button">
                                    <Link href="/about">Discover More</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="right-items">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="item">
                                            <em>01</em>
                                            <h4>170.000+ Ouvrages</h4>
                                            <p>Une collection en constante expansion pour répondre aux besoins variés de nos lecteurs.</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="item">
                                            <em>02</em>
                                            <h4>50+ <br /> Pays</h4>
                                            <p>La WCL étend son réseau mondial avec des points de lecture dans de nombreux pays.</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="item">
                                            <em>03</em>
                                            <h4>250+ bibliothèques</h4>
                                            <p>Notre objectif ambitieux de créer un réseau de bibliothèques dans 250 nations pour rendre la connaissance accessible à tous.</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="item">
                                            <em>04</em>
                                            <h4>10.000+ <br /> leaders formés</h4>
                                            <p>Nos programmes éducatifs sont conçus pour inspirer et équiper la prochaine génération de leaders.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </div>
    );
}

export default About;
