import React, { useEffect, useState, useRef } from 'react';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import Header from '../components/Header';
import Footer from '../components/Footer';
import SpinnerLoader from '../components/SpinnerLoader';
import { Swiper, SwiperSlide } from 'swiper/react';
import LazyLoad from 'react-lazyload';
import { storage } from '../firebaseConfig';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

const Catalogue = () => {
    const [books, setBooks] = useState([]);
    const [filteredBooks, setFilteredBooks] = useState([]);
    const [categories, setCategories] = useState([]);
    const [images, setImages] = useState({});
    const [loading, setLoading] = useState(true);
    const [selectedBook, setSelectedBook] = useState(null);
    const [currentBatch, setCurrentBatch] = useState(1); // Track the current batch
    const observerRef = useRef(null); // Ref for IntersectionObserver

    const booksPerBatch = 50; // Number of books/images per batch

    useEffect(() => {
        const fetchBooks = async () => {
            const query = `
                query {
                    books {
                        message
                        status
                        data
                    }
                }
            `;
            try {
                const response = await fetch('https://backend-ulws.onrender.com/graphql', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ query }),
                });

                if (!response.ok) {
                    const errorDetails = await response.json();
                    console.error('GraphQL Error:', errorDetails);
                    throw new Error('GraphQL query failed');
                }

                const result = await response.json();
                return result.data.books;
            } catch (error) {
                console.error('Fetch Error:', error);
                throw error;
            }
        };

        const loadBooks = async () => {
            setLoading(true);
            try {
                const booksData = await fetchBooks();
                console.log(booksData);
                setBooks(booksData.data);
                setFilteredBooks(booksData.data.slice(0, booksPerBatch));
                const images = await fetchImages(booksData.data.slice(0, booksPerBatch));
                setImages(images);

                const uniqueCategories = [
                    ...new Set(booksData.data.map((book) => book.category.name)),
                ];
                setCategories(uniqueCategories);
            } catch (error) {
                console.error('Error loading books:', error);
            } finally {
                setLoading(false);
            }
        };

        loadBooks();
    }, []);

    const handleBookClick = (book) => {
        setSelectedBook(book);
    };

    const fetchImageUrl = async (bookId) => {
        try {
            const storageRef = ref(storage, bookId);
            const cachedUrl = localStorage.getItem(`book-image-${bookId}`);
            if (cachedUrl) {
                return cachedUrl;
            }

            const url = await getDownloadURL(storageRef);
            localStorage.setItem(`book-image-${bookId}`, url);
            return url;
        } catch (error) {
            console.error(`Error fetching image for book ID ${bookId}:`, error);
            return './assets/images/book.jpeg';
        }
    };

    const fetchImages = async (booksData) => {
        const imageUrls = await Promise.all(
            booksData.map(async (book) => ({
                id: book.id,
                url: await fetchImageUrl(book.coverPhoto),
            }))
        );
        const imageUrlMap = {};
        imageUrls.forEach((item) => (imageUrlMap[item.id] = item.url));
        return imageUrlMap;
    };

    const loadNextBatch = async () => {
        const startIndex = currentBatch * booksPerBatch;
        const nextBatchBooks = books.slice(startIndex, startIndex + booksPerBatch);

        if (nextBatchBooks.length > 0) {
            const newImages = await fetchImages(nextBatchBooks);
            setImages((prevImages) => ({ ...prevImages, ...newImages }));
            setFilteredBooks((prevBooks) => [...prevBooks, ...nextBatchBooks]);
            setCurrentBatch((prevBatch) => prevBatch + 1);
        }
    };

    // Use IntersectionObserver to detect when the last book is visible and load more books
    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting) {
                loadNextBatch();
            }
        }, { threshold: 1.0 });

        if (observerRef.current) {
            observer.observe(observerRef.current);
        }

        return () => {
            if (observerRef.current) {
                observer.unobserve(observerRef.current);
            }
        };
    }, [filteredBooks]);

    return (
        <>
            <Header activePath="/catalogue" />
            <div className="page-heading">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="header-text">
                                <h2>Catalogue</h2>
                                <div className="div-dec"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <section className="top-section">
                <div className="search-section">
                    <div className="container">
                        <input
                            type="text"
                            id="search-input"
                            className="form-control"
                            placeholder="Rechercher par titre"
                            onChange={(event) => {
                                const searchText = event.target.value.toLowerCase();
                                const filtered = books.filter((book) =>
                                    book.title.toLowerCase().includes(searchText)
                                );
                                setFilteredBooks(filtered);
                            }}
                        />
                    </div>
                </div>
            </section>

            <div className="responsive-margin">
                {
                    loading ? (
                        <SpinnerLoader size='100px' />
                    ) : (
                        <Swiper
                            slidesPerView={4}
                            centeredSlides={true}
                            spaceBetween={30}
                            // pagination={{
                            //     type: 'fraction',
                            // }}
                            navigation={true}
                        >
                            {filteredBooks.map((book, index) => (
                                
                                <SwiperSlide key={book.id}>
                                    <LazyLoad offset={100}>
                                        <img
                                            className='large-image'
                                            src={images[book.id] || './assets/images/book.jpeg'}
                                            alt={book.title}
                                            data-bs-toggle="modal"
                                            data-bs-target="#bookModal"
                                            data-book-id={book.id}
                                            onClick={() => handleBookClick(book)}
                                        />
                                    </LazyLoad>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    )
                }
            </div>

            <div ref={observerRef}></div> {/* This is the target element for IntersectionObserver */}
            <div
                className="modal fade"
                id="bookModal"
                tabIndex="-1"
                aria-labelledby="bookModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="bookModalLabel">
                                {selectedBook?.title} ({selectedBook?.category.name})
                            </h5>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                        <div className="modal-body">
                            <div className="book-image-container">
                                <img src={images[selectedBook?.id]} alt={selectedBook?.title} className="book-image" />
                            </div>
                            <div className="book-details">
                                <h4 className="detail">ISBN : <span>{selectedBook?.isbn}</span></h4>
                                <h4 className="detail">Nombre de pages : <span>{selectedBook?.numberOfPages}</span></h4>
                                <h4 className="detail">Date de publication : <span>{selectedBook?.publicationDate}</span></h4>
                                <h4 className="detail">Maison de publication : <span>{selectedBook?.publishingHouse}</span></h4>
                                {selectedBook?.authors && (
                                    <h4 className="detail">
                                        Auteurs : <span>{selectedBook?.authors.map(author => (
                                            <span key={author.id} className="author-name"> {author?.name}, </span>
                                        ))}</span>
                                    </h4>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <Footer />
        </>
    );
};

export default Catalogue;
