import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const Header = ({ activePath }) => {
    // État pour gérer l'ouverture du menu
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    // Fonction pour basculer l'état du menu
    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    // Styles qui s'appliquent uniquement lorsque le menu est ouvert
    const navStyle = isMenuOpen
        ? {
            listStyle: 'none',
            display: 'block', // Permet d'afficher le menu en mode mobile
            flexDirection: 'column',
            backgroundColor: '#fff',
            position: 'absolute',
            left: 0,
            right: 0,
            width: '50%',
            marginLeft: 'auto',
            marginRight: 'auto',
            textAlign: 'center',
            transition: 'all 0.3s ease',
        }
        : {};

    return (
        <>
            {/* ***** Header Area Start ***** */}
            <header className="header-area header-sticky">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <nav className="main-nav">
                                {/* ***** Logo Start ***** */}
                                <Link to="/" className="logo">
                                    <img src="assets/images/logo.png" alt="Logo" />
                                </Link>
                                {/* ***** Logo End ***** */}
                                
                                {/* ***** Menu Start ***** */}
                                <ul className={`nav ${isMenuOpen ? 'open' : ''}`} style={navStyle}>
                                    <li>
                                        <Link to="/" className={activePath === '/' ? 'active' : ''}>
                                            Accueil
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/catalogue" className={activePath === '/catalogue' ? 'active' : ''}>
                                            Catalogue
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/about" className={activePath === '/about' ? 'active' : ''}>
                                            À Propos
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/service" className={activePath === '/service' ? 'active' : ''}>
                                            Nos Services
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/contact" className={activePath === '/contact' ? 'active' : ''}>
                                            Contactez-Nous
                                        </Link>
                                    </li>
                                </ul>

                                {/* Menu Trigger */}
                                <a className={`menu-trigger ${isMenuOpen ? 'active' : ''}`} onClick={toggleMenu}>
                                    <span>Menu</span>
                                </a>
                                {/* ***** Menu End ***** */}
                            </nav>
                        </div>
                    </div>
                </div>
            </header>
            {/* ***** Header Area End ***** */}
        </>
    );
};

export default Header;
