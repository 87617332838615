import React from 'react';
import About from './pages/About';
import Catalogue from './pages/Catalogue';
import Contact from './pages/Contact';
import Home from './pages/Home';
import Service from './pages/Service';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

const App = () => {
  return (
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/about' element={<About />} />
          <Route path='/catalogue' element={<Catalogue />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/service' element={<Service />} />
        </Routes>
      </BrowserRouter>
  );
}

export default App;
