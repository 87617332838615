import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';

const Contact = () => {
    return (
        <>
            <Header activePath="/contact" />
            <div className="page-heading">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="header-text">
                                <h2>Contactez-Nous</h2>
                                <div className="div-dec"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* ***** Main Banner Area End ***** */}

            <section className="map">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div id="map">
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4800.46134650992!2d13.586439775742024!3d4.5794311426953636!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x10929f0032ae76ab%3A0x8fcf5bb0603999e0!2sWorld%20Conquest%20Library!5e1!3m2!1sfr!2scm!4v1725034875724!5m2!1sfr!2scm" width="100%" height="450px" style={iframeStyle} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                            </div>
                        </div>
                        <div className="col-lg-10 offset-lg-1">
                            <div className="row">
                                <div className="col-lg-4">
                                    <div className="info-item">
                                        <i className="fa fa-envelope"></i>
                                        <h4>Adresse E-mail</h4>
                                        <a href="contact@cmfiwcl.org">contact@cmfiwcl.org</a>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="info-item">
                                        <i className="fa fa-phone"></i>
                                        <h4>Numéro de Téléphone</h4>
                                        <a href="#">(+237) 656612010 / (+237) 675852089</a>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="info-item">
                                        <i className="fa fa-map-marked-alt"></i>
                                        <h4>Adresse</h4>
                                        <a href="#">Ndoumbi, Bertoua, Cameroun QG CMCI</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="contact-us-form">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 offset-lg-3">
                            <div className="section-heading">
                                <h6>Contactez-Nous</h6>
                                <h4>N'hésitez pas à nous envoyer un message</h4>
                            </div>
                        </div>
                        <div className="col-lg-10 offset-lg-1">
                            <form id="contact" action="https://formspree.io/f/mvgplnoo" method="POST" >
                                <div className="row">
                                    <div className="col-lg-6">
                                        <fieldset>
                                            <input type="name" name="name" id="name" placeholder="Votre Nom..." autocomplete="on" required />
                                        </fieldset>
                                    </div>
                                    <div className="col-lg-6">
                                        <fieldset>
                                            <input type="phone" name="phone" id="phone" placeholder="Votre Téléphone..." autocomplete="on" required />
                                        </fieldset>
                                    </div>
                                    <div className="col-lg-6">
                                        <fieldset>
                                            <input type="text" name="email" id="email" pattern="[^ @]*@[^ @]*" placeholder="Votre E-mail..." required="" />
                                        </fieldset>
                                    </div>
                                    <div className="col-lg-6">
                                        <fieldset>
                                            <input type="subject" name="subject" id="subject" placeholder="Sujet..." autocomplete="on" />
                                        </fieldset>
                                    </div>
                                    <div className="col-lg-12">
                                        <fieldset>
                                            <textarea name="message" id="message" placeholder="Votre Message"></textarea>
                                        </fieldset>
                                    </div>
                                    <div className="col-lg-12">
                                        <fieldset>
                                            <button type="submit" id="form-submit" className="orange-button">Envoyer le Message</button>
                                        </fieldset>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
}

const iframeStyle = {
    border: '0',
    borderRadius: '5px',
    position: 'relative',
    zIndex: 2
};


export default Contact;
