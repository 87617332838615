// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getStorage, ref, getDownloadURL } from "firebase/storage"; // Import storage for Firebase Storage

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyAtlotK9mKvGQ86qpLsiSIIp-bmv1rFI0o",
    authDomain: "wclibrary-81880.firebaseapp.com",
    projectId: "wclibrary-81880",
    storageBucket: "wclibrary-81880.appspot.com",
    messagingSenderId: "877987272332",
    appId: "1:877987272332:web:92e271e8879d386f628da9"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Storage and export it
const storage = getStorage(app);

export const getFirebaseImageURL = async (imagePath) => {
    try {
        const storageRef = ref(storage, imagePath);
        const url = await getDownloadURL(storageRef);
        return url;
    } catch (error) {
        // console.error("Error getting image URL from Firebase Storage:", error);
        return null;
    }
};

export { storage };

export default app;
