import React from 'react';

const SpinnerLoader = ({ size = '50px' }) => {

    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <img
                    src='./sp.svg'
                    alt='Loading...'
                    style={{ width: size, height: size }}
                />
            </div>
        </>
    );
};

export default SpinnerLoader;
